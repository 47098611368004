export const paths = {
	index: "/",
	agents: {
		list: "/agents",
		create: "/agents/create",
	},
	clients: {
		list: "/clients",
		create: "/clients/create",
	},
	products: {
		list: "/products?tab=types",
		create: "/products/create",
	},
	settings: "/settings",
	auth: {
		login: "/login",
	},
	notAuthorized: "/401",
	notFound: "/404",
	serverError: "/500",
};
