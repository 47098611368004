import { ClientError, GraphQLClient } from "graphql-request";
import { RequestMiddleware, Response } from "graphql-request/build/esm/types";
import { ClientOptions, createClient } from "graphql-sse";
import toast from "react-hot-toast";

import { getState } from "~/store";

import { browserStorage } from "./localforage";

const requestMiddleware: RequestMiddleware = async (request) => {
	const authHeaders = await getAuthHeaders();
	return { ...request, headers: { ...request.headers, ...authHeaders } };
};

const getErrorMessages = (response: Response<unknown> | ClientError) => {
	let messages = "";

	if (response instanceof ClientError) {
		messages =
			response.response.errors?.[0]?.message ?? "Something went wrong!";
	}

	return messages;
};

const responseMiddleware = (response: Response<unknown> | ClientError) => {
	const messages = getErrorMessages(response);
	if (messages) {
		console.log("=== GQL CLIENT ERROR ===", messages);
		toast.error(messages);
	}
};

export const apiGqlClient = new GraphQLClient(process.env.API_GQL_URL!, {
	requestMiddleware,
	// @ts-expect-error - responseMiddleware is not properly typed
	responseMiddleware,
});

const getAuthHeaders = async () => {
	const obj = {} as Record<string, string>;

	const { companyId, token: _token } = getState();
	const token = _token ?? (await browserStorage.token.get());

	if (token && obj.Authorization === undefined)
		obj.Authorization = `Bearer ${token}`;
	if (companyId) obj["x-served-company-id"] = companyId;
	obj["x-served-web-app-signature"] = new Date().getTime().toString();

	return obj;
};

export const getApiGqlWsClient = () => {
	return createClient({
		url: process.env.API_GQL_WS_URL!,
		retryAttempts: Infinity,
		retry: () => new Promise((resolve) => setTimeout(resolve, 2000)),
		headers: getAuthHeaders,
		on: getWsClientLogger(),
	});
};

let reconnectToastId: string | undefined;

export const getWsClientLogger = (): ClientOptions["on"] => {
	return {
		connected: (reconnected) => {
			if (reconnected && reconnectToastId) toast.dismiss(reconnectToastId);
		},
		connecting: (reconnecting) => {
			if (reconnecting && !reconnectToastId) {
				reconnectToastId = toast.loading("Lost real time connection...", {
					duration: Infinity,
				});
			}
		},
		// message: () => {},
	};
};
