import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RouterLink } from "~/components/router-link";
import { Seo } from "~/components/seo";
import { paths } from "~/paths";
import type { Theme } from "@mui/material/styles/createTheme";
export const ServerError = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return <>
			<Seo title="Error: Server Error" data-sentry-element="Seo" data-sentry-source-file="server-error.tsx" />
			<Box component="main" sx={{
      alignItems: "center",
      display: "flex",
      flexGrow: 1,
      py: "80px"
    }} data-sentry-element="Box" data-sentry-source-file="server-error.tsx">
				<Container maxWidth="xl" data-sentry-element="Container" data-sentry-source-file="server-error.tsx">
					<Box sx={{
          display: "flex",
          justifyContent: "center",
          mb: 6
        }} data-sentry-element="Box" data-sentry-source-file="server-error.tsx">
						<Box alt="Internal server error" component="img" src="/assets/errors/error-500.png" sx={{
            height: "auto",
            maxWidth: "100%",
            width: 400
          }} data-sentry-element="Box" data-sentry-source-file="server-error.tsx" />
					</Box>
					<Typography align="center" variant={mdUp ? "h1" : "h4"} data-sentry-element="Typography" data-sentry-source-file="server-error.tsx">
						500: Internal Server Error
					</Typography>
					<Typography align="center" color="text.secondary" sx={{
          mt: 0.5
        }} data-sentry-element="Typography" data-sentry-source-file="server-error.tsx">
						You either tried some shady route or you came here by mistake.
						Whichever it is, try using the navigation.
					</Typography>
					<Box sx={{
          display: "flex",
          justifyContent: "center",
          mt: 6
        }} data-sentry-element="Box" data-sentry-source-file="server-error.tsx">
						<Button component={RouterLink} href={paths.index} data-sentry-element="Button" data-sentry-source-file="server-error.tsx">
							Back to Home
						</Button>
					</Box>
				</Container>
			</Box>
		</>;
};