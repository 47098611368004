import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { cn } from "./translations/cn";
import { de } from "./translations/de";
import { en } from "./translations/en";
import { es } from "./translations/es";

void i18n.use(initReactI18next).init({
	resources: {
		en: { translation: en },
		de: { translation: de },
		es: { translation: es },
		cn: { translation: cn },
	},
	lng: "en",
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});
