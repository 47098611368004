import { Box, CircularProgress, Typography } from "@mui/material";
import useNavigatorOnline from "use-navigator-online";
import { Dialog } from "./dialog";
const OfflineDialog = () => {
  const {
    isOffline
  } = useNavigatorOnline();
  if (isOffline) return <Dialog dialogProps={{
    maxWidth: "xs"
  }} title="No internet connection 🚫😥" content={<Box sx={{
    display: "flex",
    alignItems: "center",
    gap: 2
  }}>
						<Box>
							<CircularProgress size="32px" />
						</Box>
						<Typography variant="body2">
							You are currently offline. Check your device connection and try
							again.
						</Typography>
					</Box>} />;
  return null;
};
export { OfflineDialog };