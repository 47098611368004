import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { MeQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query Me {
		me {
			_id
			createdAt
			updatedAt
			firstName
			lastName
			phoneNumber
			email
			gender
			position
			department
			companyIssuedId
			role
			username
			avatar
			customFields {
				customField
				textValue
				selectValues
			}
		}
	}
`;

export type MeResults = MeQuery["me"];

export const meQueryKey = () => ["me"];

export const useMe = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled,
		queryKey: meQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.me),
	});
};

export const useMeCache = () => {
	return useMe(false);
};

export const meCache = () => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	return window.$queryClient?.getQueryData<MeResults>?.(meQueryKey());
};
