import { createContext } from "react";

export interface State {
	isInitialized: boolean;
}

export const initialState: State = {
	isInitialized: false,
};

export type I18nContext = State;

export const I18nContext = createContext<I18nContext>(initialState);
