import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Messaging, getMessaging } from "firebase/messaging";

const firebaseConfig = {
	apiKey: "AIzaSyBfsC2RKQJgP7RtGUW3EHOzuYwDB2caM94",
	authDomain: "served-erp.firebaseapp.com",
	projectId: "served-erp",
	storageBucket: "served-erp.appspot.com",
	messagingSenderId: "491528482232",
	appId: "1:491528482232:web:0d2228f688711b099cf34d",
	measurementId: "G-54HBBSLGZK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging: Messaging;
let analytics: Analytics;

if (typeof window === "object") {
	try {
		messaging = getMessaging(app);
		analytics = getAnalytics(app);
	} catch (e) {
		console.log("e: ", e);
	}
}

export { analytics, messaging };
