import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { browserStorage } from "~/libs/localforage";
import type { Settings } from "~/types/settings";
import { defaultSettings, initialState, SettingsContext } from "./settings-context";
import type { FC, ReactNode } from "react";
import type { State } from "./settings-context";
const restoreSettings = async (): Promise<Settings | null> => {
  let value = null;
  try {
    const restored: string | null = await browserStorage.settings.get();
    if (restored) {
      value = JSON.parse(restored);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }
  return value;
};
const deleteSettings = async (): Promise<void> => {
  try {
    await browserStorage.settings.remove();
  } catch (err) {
    console.error(err);
  }
};
const storeSettings = async (value: Record<string, any>): Promise<void> => {
  try {
    await browserStorage.settings.set(JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};
interface SettingsProviderProps {
  children?: ReactNode;
}
export const SettingsProvider: FC<SettingsProviderProps> = props => {
  const {
    children
  } = props;
  const [state, setState] = useState<State>(initialState);
  useEffect(() => {
    async function start() {
      const restored = await restoreSettings();
      setState(prevState => ({
        ...prevState,
        ...restored,
        isInitialized: true
      }));
    }
    void start();
  }, []);
  const handleReset = useCallback((): void => {
    deleteSettings().then(() => {
      setState(prevState => ({
        ...prevState,
        ...defaultSettings
      }));
    }).catch(console.error);
  }, []);
  const handleUpdate = useCallback((settings: Settings): void => {
    setState(prevState => {
      void storeSettings({
        colorPreset: prevState.colorPreset,
        contrast: prevState.contrast,
        direction: prevState.direction,
        layout: prevState.layout,
        navColor: prevState.navColor,
        paletteMode: prevState.paletteMode,
        responsiveFontSizes: prevState.responsiveFontSizes,
        stretch: prevState.stretch,
        ...settings
      });
      return {
        ...prevState,
        ...settings
      };
    });
  }, []);
  const handleDrawerOpen = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      openDrawer: true
    }));
  }, []);
  const handleDrawerClose = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      openDrawer: false
    }));
  }, []);
  const isCustom = useMemo(() => {
    return !isEqual(defaultSettings, {
      colorPreset: state.colorPreset,
      contrast: state.contrast,
      direction: state.direction,
      layout: state.layout,
      navColor: state.navColor,
      paletteMode: state.paletteMode,
      responsiveFontSizes: state.responsiveFontSizes,
      stretch: state.stretch
    });
  }, [state]);
  return <SettingsContext.Provider value={{
    ...state,
    handleDrawerClose,
    handleDrawerOpen,
    handleReset,
    handleUpdate,
    isCustom
  }} data-sentry-element="unknown" data-sentry-component="SettingsProvider" data-sentry-source-file="settings-provider.tsx">
			{children}
		</SettingsContext.Provider>;
};