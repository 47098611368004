import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import Settings03Icon from "@untitled-ui/icons-react/build/esm/Settings03";
import type { FC } from "react";
interface SettingsButtonProps {
  onClick?: () => void;
}
export const SettingsButton: FC<SettingsButtonProps> = props => <Tooltip title="Settings" data-sentry-element="Tooltip" data-sentry-component="SettingsButton" data-sentry-source-file="settings-button.tsx">
		<Box sx={{
    backgroundColor: "background.paper",
    borderRadius: "50%",
    bottom: 0,
    boxShadow: 16,
    margin: theme => theme.spacing(4),
    position: "fixed",
    right: 0,
    zIndex: theme => theme.zIndex.speedDial
  }} {...props} data-sentry-element="Box" data-sentry-source-file="settings-button.tsx">
			<ButtonBase sx={{
      backgroundColor: "primary.main",
      borderRadius: "50%",
      color: "primary.contrastText",
      p: "10px"
    }} data-sentry-element="ButtonBase" data-sentry-source-file="settings-button.tsx">
				<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="settings-button.tsx">
					<Settings03Icon data-sentry-element="Settings03Icon" data-sentry-source-file="settings-button.tsx" />
				</SvgIcon>
			</ButtonBase>
		</Box>
	</Tooltip>;