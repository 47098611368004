import Badge, { badgeClasses } from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import RefreshCcw01Icon from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { useCallback } from "react";
import { Scrollbar } from "~/components/scrollbar";
import type { Settings } from "~/types/settings";
import { OptionsColorPreset } from "./options-color-preset";
import { OptionsColorScheme } from "./options-color-scheme";
import { OptionsContrast } from "./options-contrast";
import { OptionsDirection } from "./options-direction";
import { OptionsLayout } from "./options-layout";
import { OptionsNavColor } from "./options-nav-color";
import { OptionsStretch } from "./options-stretch";
import type { FC } from "react";
interface SettingsDrawerProps {
  canReset?: boolean;
  onClose?: () => void;
  onReset?: () => void;
  onUpdate?: (settings: Settings) => void;
  open?: boolean;
  values?: Settings;
}
export const SettingsDrawer: FC<SettingsDrawerProps> = props => {
  const {
    canReset,
    onClose,
    onUpdate,
    onReset,
    open,
    values = {},
    ...other
  } = props;
  const handleFieldUpdate = useCallback((field: keyof Settings, value: unknown): void => {
    onUpdate?.({
      [field]: value
    });
  }, [onUpdate]);
  return <Drawer disableScrollLock anchor="right" onClose={onClose} open={open} ModalProps={{
    BackdropProps: {
      invisible: true
    },
    sx: {
      zIndex: 1400
    }
  }} PaperProps={{
    elevation: 24,
    sx: {
      maxWidth: "100%",
      width: 440
    }
  }} {...other} data-sentry-element="Drawer" data-sentry-component="SettingsDrawer" data-sentry-source-file="settings-drawer.tsx">
			<Scrollbar sx={{
      height: "100%",
      "& .simplebar-content": {
        height: "100%"
      },
      "& .simplebar-scrollbar:before": {
        background: "var(--nav-scrollbar-color)"
      }
    }} data-sentry-element="Scrollbar" data-sentry-source-file="settings-drawer.tsx">
				<Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3} sx={{
        px: 3,
        pt: 2
      }} data-sentry-element="Stack" data-sentry-source-file="settings-drawer.tsx">
					<Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="settings-drawer.tsx">App Settings</Typography>
					<Stack alignItems="center" direction="row" spacing={0.5} data-sentry-element="Stack" data-sentry-source-file="settings-drawer.tsx">
						<Badge anchorOrigin={{
            horizontal: "right",
            vertical: "top"
          }} color="error" sx={{
            [`& .${badgeClasses.badge}`]: {
              top: 6,
              right: 6,
              ...(!canReset && {
                display: "none"
              })
            }
          }} variant="dot" data-sentry-element="Badge" data-sentry-source-file="settings-drawer.tsx">
							<IconButton color="inherit" onClick={onReset} data-sentry-element="IconButton" data-sentry-source-file="settings-drawer.tsx">
								<SvgIcon fontSize="small" data-sentry-element="SvgIcon" data-sentry-source-file="settings-drawer.tsx">
									<RefreshCcw01Icon data-sentry-element="RefreshCcw01Icon" data-sentry-source-file="settings-drawer.tsx" />
								</SvgIcon>
							</IconButton>
						</Badge>
						<IconButton color="inherit" onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="settings-drawer.tsx">
							<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="settings-drawer.tsx">
								<XIcon data-sentry-element="XIcon" data-sentry-source-file="settings-drawer.tsx" />
							</SvgIcon>
						</IconButton>
					</Stack>
				</Stack>
				<Stack spacing={5} sx={{
        p: 3
      }} data-sentry-element="Stack" data-sentry-source-file="settings-drawer.tsx">
					<OptionsColorPreset onChange={value => handleFieldUpdate("colorPreset", value)} value={values.colorPreset} data-sentry-element="OptionsColorPreset" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsColorScheme onChange={value => handleFieldUpdate("paletteMode", value)} value={values.paletteMode} data-sentry-element="OptionsColorScheme" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsNavColor onChange={value => handleFieldUpdate("navColor", value)} value={values.navColor} data-sentry-element="OptionsNavColor" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsLayout onChange={value => handleFieldUpdate("layout", value)} value={values.layout} data-sentry-element="OptionsLayout" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsStretch onChange={value => handleFieldUpdate("stretch", value)} value={values.stretch} data-sentry-element="OptionsStretch" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsContrast onChange={value => handleFieldUpdate("contrast", value)} value={values.contrast} data-sentry-element="OptionsContrast" data-sentry-source-file="settings-drawer.tsx" />
					<OptionsDirection onChange={value => handleFieldUpdate("direction", value)} value={values.direction} data-sentry-element="OptionsDirection" data-sentry-source-file="settings-drawer.tsx" />
				</Stack>
			</Scrollbar>
		</Drawer>;
};